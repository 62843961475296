.financements {
    &__fiche {
        &__bloc-header {
            @extend .h4;
            margin-bottom: 20px;
            font-weight: bold;
        }

        &-options {
            .financements__fiche-option:nth-of-type(1) {
                border-top: 1px solid color("grey", "300");
            }
        }

        &-option {
            border-bottom: 1px solid color("grey", "300");
            color: color("grey", "dark");
        }

        &__option {
            &-title {
                display: flex;
                justify-content: center;
                align-items: flex-start;
                color: color("secondary", "base");
                font-size: 14px;
                font-weight: 500;
            }

            &_data {
                &-repeater {
                    border-bottom: 1px solid color("grey", "300");
                }

                &-repeater:last-child {
                    border-bottom: none;
                }

                &-row {
                    display: flex;
                    padding: 10px 0;
                }

                &-comments,
                &-conditions {
                    padding: 10px 0;
                }

                &-indemnites,
                &-table,
                &-suivi-conditions {
                    display: flex;
                    margin: 10px 0;

                    .financements__fiche__option-title {
                        align-items: center;
                        font-size: inherit;
                        font-weight: inherit;
                        color: color("grey", "dark") !important;
                    }

                    .financements__fiche__option_data-row {
                        flex-direction: column;
                    }

                    .financements__fiche__option_data-repeater {
                        display: flex;
                        padding-bottom: 10px;
                    }

                    .financements__fiche__option_data-repeater > * {
                        padding: 0 7px;
                    }
                }

                &-indemnite {
                    &-montant-determine {
                        display: flex;
                    }
                }

                &-table {
                    &-montant-min {
                        display: flex;
                    }
                }

                &-montant-min,
                &-montant-max,
                &-prix {
                    display: flex;
                }
            }
        }
    }
}
