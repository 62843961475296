$calendar-header-height: 60px;
$calendar-day-height: 130px;
$calendar-border-color: color("grey", "300");

.calendrier {
    display: flex;
    flex-direction: column;
    height: 100%;

    &-calendar {
        display: flex;
    }

    &-filters {
        &-event {
            display: flex;
            align-items: stretch;

            &:before {
                content: "";
                width: 7px;
                min-width: 7px;
                margin-right: 7px;
            }

            &--remboursement:before {
                background-color: color("calendar", "remboursement");
            }
            &--tirage:before {
                background-color: color("calendar", "tirage");
            }
            &--interets_payes:before {
                background-color: color("calendar", "interets_payes");
            }
            &--interets_recus:before {
                background-color: color("calendar", "interets_recus");
            }
            &--consolidation:before {
                background-color: color("calendar", "consolidation");
            }
            &--prorogation:before {
                background-color: color("calendar", "prorogation");
            }
            &--passage_taux_fixe:before {
                background-color: color("calendar", "passage_taux_fixe");
            }
            &--passage_taux_variable:before {
                background-color: color("calendar", "passage_taux_variable");
            }
            &--changement_indice:before {
                background-color: color("calendar", "changement_indice");
            }
            &--make_whole_redemption_call:before {
                background-color: color("calendar", "make_whole_redemption_call");
            }
            &--make_whole_amount:before {
                background-color: color("calendar", "make_whole_amount");
            }
            &--suivi_covenants_libre:before {
                background-color: color("calendar", "suivi_covenants_libre");
            }
            &--soft_call:before {
                background-color: color("calendar", "soft_call");
            }
            &--excess_cash_flow:before {
                background-color: color("calendar", "excess_cash_flow");
            }
            &--suivi_covenants_ratio:before {
                background-color: color("calendar", "suivi_covenants_ratio");
            }
            &--frais:before {
                background-color: color("calendar", "frais");
            }
            &--prime_emission_payee:before {
                background-color: color("calendar", "prime_emission_payee");
            }
            &--prime_emission_recue:before {
                background-color: color("calendar", "prime_emission_recue");
            }
            &--prime_remboursement_payee:before {
                background-color: color("calendar", "prime_remboursement_payee");
            }
            &--prime_remboursement_recue:before {
                background-color: color("calendar", "prime_remboursement_recue");
            }
            &--commissions_utilisation:before {
                background-color: color("calendar", "commissions_utilisation");
            }
            &--commissions_non_utilisation:before {
                background-color: color("calendar", "commissions_non_utilisation");
            }
            &--constatation_ratio:before {
                background-color: color("calendar", "constatation_ratio");
            }
        }
    }

    &-grid {
        flex-grow: 1;
        // width: 100%;
        margin-left: calc(2 * #{$spacing-unit});
    }

    &-header {
        display: flex;
        justify-content: space-between;

        &--cell {
            width: 100%;
            font-weight: bold;
            height: $calendar-header-height;
            display: flex;
            justify-content: center;
            align-items: center;
            color: color("shades", "black");
            font-size: 16px;

            & + & {
                border-left: 1px solid $calendar-border-color;
            }
        }
    }

    &-month {
        width: 100%;
        background-color: color("grey", "light");
        display: flex;
        flex-direction: column;
        flex: 1 1 0%;
        height: 100%;
    }

    &-week {
        position: relative;
        width: 100%;
        // height: 130px;
        display: flex;
        justify-content: space-between;
        background-color: color("grey", "light");

        &--day {
            position: absolute;
            display: flex;
            width: 100%;

            .calendrier-day {
                border-top: 1px solid $calendar-border-color;
            }
        }

        &--content {
            width: 100%;
        }

        &--period-events {
            display: flex;
            flex-direction: column;
            position: relative;
            // overflow: hidden;
            width: 100%;
            margin-top: 35px;
        }

        &--period-event {
            margin: 2px 0;
            padding: 5px 10px;
            background-color: red;
            border-radius: 4px;
            cursor: pointer;
        }

        &--events {
            display: flex;
            width: 100%;
        }

        &-events {
            &--list {
                display: flex;
                position: relative;
                // overflow: hidden;
                width: 100%;
            }
        }

        &-period-event {
            &--remboursement {
                background-color: color("calendar", "remboursement");
            }
            &--tirage {
                background-color: color("calendar", "tirage");
            }
            &--interets_payes {
                background-color: color("calendar", "interets_payes");
            }
            &--interets_recus {
                background-color: color("calendar", "interets_recus");
            }
            &--consolidation {
                background-color: color("calendar", "consolidation");
            }
            &--prorogation {
                background-color: color("calendar", "prorogation");
            }
            &--passage_taux_fixe {
                background-color: color("calendar", "passage_taux_fixe");
            }
            &--passage_taux_variable {
                background-color: color("calendar", "passage_taux_variable");
            }
            &--changement_indice {
                background-color: color("calendar", "changement_indice");
            }
            &--make_whole_redemption_call {
                background-color: color("calendar", "make_whole_redemption_call");
            }
            &--make_whole_amount {
                background-color: color("calendar", "make_whole_amount");
            }
            &--suivi_covenants_libre {
                background-color: color("calendar", "suivi_covenants_libre");
            }
            &--soft_call {
                background-color: color("calendar", "soft_call");
            }
            &--excess_cash_flow {
                background-color: color("calendar", "excess_cash_flow");
            }
            &--suivi_covenants_ratio {
                background-color: color("calendar", "suivi_covenants_ratio");
            }
            &--frais:before {
                background-color: color("calendar", "frais");
            }
            &--prime_emission_payee:before {
                background-color: color("calendar", "prime_emission_payee");
            }
            &--prime_emission_recue:before {
                background-color: color("calendar", "prime_emission_recue");
            }
            &--prime_remboursement_payee:before {
                background-color: color("calendar", "prime_remboursement_payee");
            }
            &--prime_remboursement_recue:before {
                background-color: color("calendar", "prime_remboursement_recue");
            }
            &--commissions_utilisation:before {
                background-color: color("calendar", "commissions_utilisation");
            }
            &--commissions_non_utilisation:before {
                background-color: color("calendar", "commissions_non_utilisation");
            }
            &--constatation_ratio:before {
                background-color: color("calendar", "constatation_ratio");
            }
        }
    }

    &-day {
        width: 100%;
        padding: 10px 5px 5px 5px;
        height: $calendar-day-height;
        background-color: color("shades", "grey");
        position: relative;

        & + & {
            border-left: 1px solid $calendar-border-color;
        }

        &--full {
            height: unset;
            min-height: $calendar-day-height;
        }

        &--header {
            width: 100%;
            text-align: center;
        }

        &--number {
            margin-top: 8px;
            font-size: 12px;
            font-weight: 500;
            letter-spacing: 0.3px;
            display: inline-block;
            text-align: center;
            white-space: nowrap;
            width: max-content;
            min-width: 24px;
            color: color("grey", "dark");
            line-height: 16px;
        }

        &--out-of-month {
            background-color: color("grey", "300");
        }
    }

    &-event {
        margin-bottom: 10px;
        height: 25px;
        display: flex;
        align-items: center;
        cursor: pointer;
        position: relative;

        p {
            display: -webkit-box;
            max-width: 90%;
            text-overflow: ellipsis;
            font-size: 12px;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            overflow: hidden;
        }

        &:before {
            content: "";
            display: block;
            width: 7px;
            height: 100%;
            margin-right: 7px;
        }

        &--remboursement:before {
            background-color: color("calendar", "remboursement");
        }
        &--tirage:before {
            background-color: color("calendar", "tirage");
        }
        &--interets_payes:before {
            background-color: color("calendar", "interets_payes");
        }
        &--interets_recus:before {
            background-color: color("calendar", "interets_recus");
        }
        &--consolidation:before {
            background-color: color("calendar", "consolidation");
        }
        &--prorogation:before {
            background-color: color("calendar", "prorogation");
        }
        &--passage_taux_fixe:before {
            background-color: color("calendar", "passage_taux_fixe");
        }
        &--passage_taux_variable:before {
            background-color: color("calendar", "passage_taux_variable");
        }
        &--changement_indice:before {
            background-color: color("calendar", "changement_indice");
        }
        &--make_whole_redemption_call:before {
            background-color: color("calendar", "make_whole_redemption_call");
        }
        &--make_whole_amount:before {
            background-color: color("calendar", "make_whole_amount");
        }
        &--suivi_covenants_libre:before {
            background-color: color("calendar", "suivi_covenants_libre");
        }
        &--soft_call:before {
            background-color: color("calendar", "soft_call");
        }
        &--excess_cash_flow:before {
            background-color: color("calendar", "excess_cash_flow");
        }
        &--suivi_covenants_ratio:before {
            background-color: color("calendar", "suivi_covenants_ratio");
        }
        &--frais:before {
            background-color: color("calendar", "frais");
        }
        &--prime_emission_payee:before {
            background-color: color("calendar", "prime_emission_payee");
        }
        &--prime_emission_recue:before {
            background-color: color("calendar", "prime_emission_recue");
        }
        &--prime_remboursement_payee:before {
            background-color: color("calendar", "prime_remboursement_payee");
        }
        &--prime_remboursement_recue:before {
            background-color: color("calendar", "prime_remboursement_recue");
        }
        &--commissions_utilisation:before {
            background-color: color("calendar", "commissions_utilisation");
        }
        &--commissions_non_utilisation:before {
            background-color: color("calendar", "commissions_non_utilisation");
        }
        &--constatation_ratio:before {
            background-color: color("calendar", "constatation_ratio");
        }
    }

    &-tooltip {
        position: absolute;
        z-index: 20;
        border-radius: $border-radius;
        background-color: color("shades", "white");
        border: 1px solid $calendar-border-color;
        padding: $spacing-unit $spacing-unit;

        &--month,
        &--week {
            width: 325%;
        }

        &--day {
            width: 50%;
            left: 25%;
        }

        &--period {
            width: 45%;
        }

        &--left {
            right: 100%;
        }

        &-period--left {
            right: 15%;
        }

        &--right {
            left: 100%;
        }

        &-period--right {
            left: 15%;
        }

        &-period--top,
        &--top {
            top: 0;
        }

        &-period--bottom,
        &--bottom {
            bottom: 0;
        }

        &-header {
            display: flex;
            align-items: center;
            position: relative;
            height: 28px;

            &-title {
                font-size: 16px;
                font-weight: bold;
            }
        }

        &-close {
            cursor: pointer;
            position: absolute;
            top: 0;
            right: 0;
            width: 28px;
            height: 28px;
            border-radius: 28px;
            background: transparent;
            border: 2px solid color("grey", "base");

            &::after,
            &::before {
                content: "";
                display: block;
                height: 80%;
                width: 2px;
                background-color: color("grey", "base");
                position: absolute;
                top: 10%;
                left: calc(50% - 1px);
            }

            &::before {
                transform: rotate(-45deg);
            }

            &::after {
                transform: rotate(45deg);
            }

            @include event_attention() {
                border: 2px solid darken(color("grey", "base"), 10);
                text-decoration: none;
                outline-width: 0;
                &::after,
                &::before {
                    background-color: darken(color("grey", "base"), 10);
                }
            }
        }
    }

    &-table {
        margin-top: $spacing-unit;

        .table__col:first-child {
            text-align: left;
            font-weight: bold;
            color: color("shades", "black");
        }
    }

    &_data {
        padding: $spacing-unit * 2;
        background-color: color("shades", "white");
        border-radius: $border-radius;
        box-shadow: $box-shadow;

        .chart-tooltip__item-value {
            text-align: right;
        }
    }
}
