$primary: (
    "base": #2babe2,
    "dark": #007cb0,
);

$secondary: (
    "base": #0871b8,
    "dark": #004788,
);

$red: (
    "base": #dc2321,
    "dark": #c03f36,
);

$teal: (
    "base": #0ae1c4,
    "dark": #02c4aa,
);

$grey: (
    "base": #c0c3cd,
    "light": #f7f7fb,
    "dark": #9191a4,
    "disabled": #abb3b9,
    "disabled-text": #545454,
    "50": #f7f6fb,
    "100": #ecedf0,
    "200": #e0e1e6,
    "300": #d4d9e7,
    "400": #c9ccd5,
    "500": #c0c3cd,
    "600": #babdc8,
    "700": #b2b5c1,
    "800": #aaaeba,
    "900": #9ca1ae,
);

$green: (
    "base": #75c718,
);

$pink: (
    "base": #db65cf,
);

$purple: (
    "base": #bf85fd,
);

$orange: (
    "base": #fa5523,
);

$yellow: (
    "base": #ffea17,
);

$blue: (
    "base": #005073,
);

$shades: (
    "black": #000000,
    "white": #ffffff,
    "grey": #f5f6fa,
    "transparent": transparent,
);

$calendar: (
    "remboursement": #993f00,
    "tirage": #4c005c,
    "interets_payes": #191919,
    "interets_recus": #005c31,
    "consolidation": #2bce48,
    "prorogation": #ffcc99,
    "passage_taux_fixe": #808080,
    "passage_taux_variable": #94ffb5,
    "changement_indice": #8f7c00,
    "make_whole_redemption_call": #9dcc00,
    "make_whole_amount": #c20088,
    "suivi_covenants_libre": #003380,
    "soft_call": #ffa405,
    "excess_cash_flow": #ffa8bb,
    "suivi_covenants_ratio": #426600,
    "frais": #ff0010,
    "prime_emission_payee": #5ef1f2,
    "prime_emission_recue": #00998f,
    "prime_remboursement_payee": #e0ff66,
    "prime_remboursement_recue": #740aff,
    "commissions_utilisation": #990000,
    "commissions_non_utilisation": #ffff80,
    "constatation_ratio": #ffff00,
);

$colors: (
    "primary": $primary,
    "secondary": $secondary,
    "red": $red,
    "teal": $teal,
    "grey": $grey,
    "green": $green,
    "pink": $pink,
    "purple": $purple,
    "orange": $orange,
    "yellow": $yellow,
    "blue": $blue,
    "shades": $shades,
    "calendar": $calendar,
);

$box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.2);
