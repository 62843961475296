.financements {
    &__saisie {
        &__nav {
            width: 700px;
            max-width: 100%;
            margin: $spacing-unit * 3 auto;
            text-align: center;

            &__items {
                display: flex;
                flex-wrap: wrap;

                & > * {
                    width: calc(50% - #{$spacing-unit * 2});
                    margin: $spacing-unit !important;
                    padding: $spacing-unit * 2 !important;
                    line-height: 100px;
                    font-size: 1.8rem;
                }
            }
        }
        &__actions {
            margin: 0;

            .btn {
                &--export,
                &--save {
                    @include event_attention() {
                        background-color: color("secondary", "base");
                    }
                }
                &--save-temp {
                    @include event_attention() {
                        background-color: color("teal", "dark");
                    }
                }
                &--cancel {
                    @include event_attention() {
                        background-color: color("red", "base");
                    }
                }
                &--export,
                &--save,
                &--save-temp,
                &--cancel {
                    padding: 10px 30px;
                    color: color("grey", "dark");

                    @include event_attention() {
                        color: color("shades", "white");
                    }
                }
                &--secondary:disabled {
                    color: color("grey", "disabled") !important;
                }
                &--save-temp:disabled {
                    @include event_attention() {
                        color: color("shades", "white");
                    }
                }
            }
        }
        &__contrat {
            &__lignes {
                position: relative;
                padding: $spacing-unit * 2 $spacing-unit * 3 $spacing-unit * 3;
                background: color("shades", "white");
                box-shadow: $box-shadow;
                z-index: 10;

                .btn--full {
                    margin-top: 5px;
                }
                &:before {
                    content: "";
                    position: absolute;
                    height: 10px;
                    width: 100%;
                    top: -10px;
                    left: 0;
                    background: color("shades", "white");
                }
            }
        }
        &__tabs {
            display: flex;
            justify-content: space-around;
            margin: $spacing-unit * 2 0;
            padding: $spacing-unit * 2.5 0 $spacing-unit * 2;
            text-align: center;

            &-item {
                cursor: pointer;

                h2 {
                    margin: 0;
                    color: color("secondary", "base");

                    span {
                        display: block;
                        margin-bottom: $spacing-unit;

                        &:last-of-type {
                            margin-bottom: 0;
                        }
                    }
                }
                &.is-current {
                    h2 {
                        color: color("shades", "black");
                    }
                }
                &.is-disabled {
                    cursor: not-allowed;

                    h2 {
                        color: color("grey", "disabled");
                    }
                }
                &:not(.is-disabled):hover {
                    h2 {
                        color: color("primary", "base");
                    }
                }
            }
        }
        &__form {
            .grille-gutter--3 {
                .grille-1,
                .grille-2,
                .grille-3,
                .grille-4,
                .grille-5,
                .grille-6,
                .grille-7,
                .grille-8,
                .grille-9,
                .grille-10,
                .grille-11 {
                    margin-bottom: 20px;
                }
            }
        }
        &__ligne {
            &-cles {
                .btn--link {
                    margin-top: 15px;
                    color: color("secondary", "base");
                }
                &-header {
                    display: flex;
                    justify-content: space-between;
                    margin-bottom: -10px;

                    h4 {
                        margin: 0;
                    }
                    .btn--link {
                        margin-top: 0;
                    }
                }
            }
        }
        &__marges-indexees {
            padding: $spacing-unit * 2;
            border: 1px solid color("grey", "300");
            border-radius: $border-radius;

            & > table {
                width: 100%;
                margin: 0 auto;
                text-align: right;

                tr {
                    td {
                        padding: 0 5px;

                        &:nth-of-type(2) {
                            width: 100px;
                        }
                        &:nth-of-type(3),
                        &:nth-of-type(4),
                        &:nth-of-type(5) {
                            width: 120px;
                        }
                        &:last-of-type {
                            width: 100px;
                        }
                        .field-like {
                            position: relative;
                            display: inline-block;
                            min-height: 46px;
                            margin: 2px 11px 0;
                            padding: 15px 15px 15px 20px;
                            color: color("grey", "disabled-text");
                            border: 1px solid color("grey", "300");
                            border-radius: $border-radius;
                            overflow: hidden;
                            vertical-align: middle;
                            cursor: not-allowed;

                            &:nth-of-type(2) {
                                width: 111px;
                                text-align: center;
                            }
                        }
                    }
                }
            }
            &__actions {
                td {
                    width: 25% !important;
                    border: 1px solid transparent;

                    button {
                        width: 100%;
                    }
                }
            }
        }
        &__lignes-backup {
            & > table {
                width: 700px;
                max-width: 100%;
            }
        }
        &__evolutions {
            &-item {
                &.bordered {
                    margin: 30px -15px;
                    padding: 20px 10px 0px;
                    border: 1px solid color("grey", "300");
                    border-radius: $border-radius;
                    box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.1);
                }
                &:first-of-type.bordered {
                    margin-top: 0;
                }
                &__title {
                    display: flex;
                    justify-content: space-between;
                    margin-bottom: 20px;

                    h3 {
                        margin-bottom: 0;
                        font-size: 1.5rem;
                    }
                    &-date,
                    &-remove {
                        width: 200px;
                    }
                    &-date {
                        margin-top: -5px;
                    }
                    &-remove {
                        text-align: right;
                    }
                }
            }
        }
        &__frais,
        &__commision,
        &__options {
            &-item {
                display: flex;
                justify-content: space-between;
                padding-bottom: $spacing-unit;

                & > div:first-child {
                    width: calc(100% - 37px);
                }
                & > div:last-child {
                    width: 27px;
                    margin-top: 5px;
                }
                .error-echeancier {
                    color: color("red", "base");
                }
            }
        }
        &__options {
            &-subfieldset {
                margin-top: 5px;
                padding: $spacing-unit;
                padding-bottom: 0;
                border: 1px solid color("grey", "300");
                border-radius: $border-radius;

                &-title {
                    margin: 0;
                    margin-bottom: $spacing-unit / 2;
                    color: color("grey", "dark");
                    text-align: center;
                }
                & .financements__saisie__options {
                    &-item {
                        padding: $spacing-unit;
                        border: 1px solid color("grey", "300");
                        border-radius: $border-radius;
                        box-shadow: none;
                    }
                }
            }
        }
        &__commission {
            &-item {
                display: flex;
                justify-content: space-between;

                button {
                    max-height: 26px;
                }
            }

            &-content {
                width: 100%;
                margin-right: 25px;
            }
        }
    }
}
